@import url("https://rsms.me/inter/inter.css");
@import url("https://fonts.googleapis.com/css2?family=Antonio:wght@600&display=swap");

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: linear-gradient(180deg, #070707 0%, #000000 80%);
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  font-family: "Inter var", sans-serif;
}

p {
  margin: 0;
  padding: 0;
}



@media screen and (max-width: 568px) {
  .full {
    visibility: hidden;
    display: none;
  }
}
